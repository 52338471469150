import React from "react"
import { Link } from 'gatsby'

// Base Component
import ComponentLayout from 'layouts/component.layout'
import LinkComponent from 'components/link'

const ImageTextComponent = ({ props }) => {
  if (props.edges.length) {
    const data = props.edges[0].node.imageText
    const renderLink = (elem) => {
      if (elem) {
        return (
          <Link to={ elem }>
            <LinkComponent text="Learn more" />
          </Link>
        )
      }
    }

    const createComponents = () => {
      return (
        data.slice(1).map((edge, index) => {
          const oddText = (index % 2 === 0) ? 'lg:col-start-8 lg:col-end-13' : 'lg:col-start-1 lg:col-end-7'
          const oddImage = (index % 2 !== 0) ? 'lg:col-start-8 lg:col-end-13' : 'lg:col-start-1 lg:col-end-7'
          return (
            <ComponentLayout className="grid-flow-col pb-38" key={`image-text-${index}`}>
              <div className={`col-start-1 col-end-3 md:col-start-1 md:col-end-7 flex ${oddImage}`}>
                <img className="self-center" alt={ edge.image.title } src={ edge.image.file.url } loading="lazy" />
              </div>
              <div className={`col-start-1 col-end-3 md:col-start-1 md:col-end-7 md:flex md:justify-center md:flex-col ${oddText}`}>
                <h2 className="text-4xl lg:text-5xl leading-large sm:hyphen">
                  <span className="text-xs leading-standard uppercase tracking-standard">
                    { edge.subtitle }
                  </span>
                  { edge.title }
                </h2>
                <div className="text-lg leading-standard my-6" dangerouslySetInnerHTML={{__html: edge.body.childMarkdownRemark.html}}/>
                { renderLink(edge.link) }
              </div>
            </ComponentLayout>
          )
        })
      )
    }

    const renderComponents = () => {
      if (data.length > 1) {
        return (
          <div className="bg-white">
            { createComponents() }
          </div>
        )
      }
    }

    return (
      <>
        <ComponentLayout className="grid-flow-col pb-38" key={`image-text-0`}>
          <div className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-8 lg:col-end-13 flex">
            <img className="self-center" alt={ data[0].image.title } src={ data[0].image.file.url } loading="lazy" />
          </div>
          <div className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-span-6 text-white md:flex md:justify-center md:flex-col">
            <h2 className="text-4xl lg:text-5xl leading-large sm:hyphen">
              <span className="text-xs leading-standard uppercase tracking-standard">
                { data[0].subtitle }
              </span>
              { data[0].title }
            </h2>
            <div className="text-lg leading-standard my-6" dangerouslySetInnerHTML={{__html: data[0].body.childMarkdownRemark.html}}/>
            { renderLink(data[0].link) }
          </div>
        </ComponentLayout>
        <svg className="wave-outer min-w-full" width="100%" height="100%" viewBox="0 0 6000 404">
          <path className="wave" d="M-0.001,535.333l6000,0l0,-352.756c-452.778,-120.385 -837.5,-180.577 -1154.17,-180.577c-1127.07,0 -1155.94,487.919 -1948.49,385.712c-227.895,-29.389 -568.681,-277.608 -980.678,-277.608c-447.397,0 -717.076,281.179 -1063.2,229.962c-409.912,-60.655 -527.655,-198.118 -853.463,-338.066l0,533.333Z"/>
        </svg>

        { renderComponents() }
      </>
    )
  }

  return (
    <svg className="wave-outer min-w-full" width="100%" height="100%" viewBox="0 0 6000 404">
      <path className="wave" d="M-0.001,535.333l6000,0l0,-352.756c-452.778,-120.385 -837.5,-180.577 -1154.17,-180.577c-1127.07,0 -1155.94,487.919 -1948.49,385.712c-227.895,-29.389 -568.681,-277.608 -980.678,-277.608c-447.397,0 -717.076,281.179 -1063.2,229.962c-409.912,-60.655 -527.655,-198.118 -853.463,-338.066l0,533.333Z"/>
    </svg>
  )
}

export default ImageTextComponent
